import { LocalizationProvider } from '@mui/x-date-pickers';
import { ReactNode } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pl';
import { LocalizationProviderProps } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';

interface CustomLocalizationProviderProps {
  children: ReactNode;
  localizationProviderProps?: LocalizationProviderProps;
}

export const CustomizedMuiLocalizationProvider = (props: CustomLocalizationProviderProps) => (
  <LocalizationProvider
    {...props.localizationProviderProps}
    dateAdapter={AdapterDayjs}
    adapterLocale={'pl'}
  >
    {props.children}
  </LocalizationProvider>
);
