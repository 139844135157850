import { ReactNode } from 'react';
import { RenderFirsOneOfChildrenOnConditionMetOrSecond } from '../RenderOneOfChildrenConditionally';
import { LoadingSpinner } from './loadingSpinner';

interface RenderContentOrSpinnerProps {
  isLoading: boolean;
  children: ReactNode;
}

export const RenderContentOrLoadingSpinner = (props: RenderContentOrSpinnerProps) => {
  const { isLoading, children } = props;

  return (
    <>
      <RenderFirsOneOfChildrenOnConditionMetOrSecond condition={isLoading}>
        <LoadingSpinner />
        {children}
      </RenderFirsOneOfChildrenOnConditionMetOrSecond>
    </>
  );
};
