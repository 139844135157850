import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(utc);
dayjs.extend(Timezone);
dayjs.extend(customParseFormat);

export const fromApiIsoStringDateToDateJs = (dateAsString: string): Dayjs => dayjs(dateAsString);
export const fromIsoStringToFormatedString = (dateIsoString: string, format: string): string =>
  dayjs(dateIsoString).format(format);
export const fromIsoStringToYearMonthDayHouerMinuteSecond = (
  dateIsoString: string | null | undefined,
  noSeconds?: boolean
): string => {
  if (!dateIsoString || dateIsoString === '-') {
    return '-';
  }
  if (noSeconds) {
    return dayjs(dateIsoString).format('YYYY-MM-DD HH:mm');
  }
  return dayjs(dateIsoString).format('YYYY-MM-DD HH:mm:ss');
};

function isIsoDateString(value: any): boolean {
  const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{1,6})?$/;
  return typeof value === 'string' && isoDateRegex.test(value);

  /*
  Here's a breakdown of the regex pattern:
  ^: asserts the start of the string
  (\d{4})-: matches exactly 4 digits (the year) followed by a hyphen
  (\d{2})-: matches exactly 2 digits (the month) followed by a hyphen
  (\d{2})T: matches exactly 2 digits (the day) followed by the letter 'T'
  (\d{2}):: matches exactly 2 digits (the hour) followed by a colon
  (\d{2}):: matches exactly 2 digits (the minute) followed by a colon
  (\d{2}): matches exactly 2 digits (the second)
  (\.\d{1,6})?: matches an optional decimal point followed by 1 to 6 digits (the microseconds)
  $: asserts the end of the string
  */
}

/*
export function processDates(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => processDates(item));
  }
  const result: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (isIsoDateString(value)) {
        try {
          const formattedDateInPLTimeZone = dayjs.utc(value).tz('Europe/Warsaw').format('YYYY-MM-DD HH:mm:ss');
          // value is in Isostring in UTC. to produce dayjs object also in utc we need to use
          // dayjs.utc(value) otherwise it will treat it as local time and tz function will not work
          result[key] = formattedDateInPLTimeZone;
        } catch (e) {
          result[key] = value;
        }
      } else {
        result[key] = processDates(value);
      }
    }
  }
  return result;
}
*/

export const fromDayjsObjectToIsoString = (date: Dayjs | null): string | undefined => {
  let result: string | undefined;
  if (date) {
    result = date?.format();
  }
  return result;
};

export const fromPolishDayjsObjectToUTcIsoString = (date: Dayjs | null): string | undefined => {
  let result: string | undefined;
  if (date) {
    result = date?.utc().toISOString();
  }
  return result;
};

export const findMostRecentDate = (dates: Dayjs[]): Dayjs => {
  let result: Dayjs = dayjs('1900-01-01');
  dates.forEach((date) => {
    if (date.isAfter(result)) {
      result = date;
    }
  });
  return result;
};
export default dayjs;
