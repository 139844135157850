// components
import { GridColumns } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { API_URL, UrlConfig, useFetch } from '../../../hooks/useFetchHooksi';
import { Package } from '../../../Dtos/package';
import { MoreMenuReusable } from '../../../components/Common/MoreMenuReusable';
import { MainPageWithTable } from '../../../components/Common/PageWraper/mainPageWithTable';
import { useTableHook } from '../../../hooks/useTableHook';
import { RenderContentOrLoadingSpinner } from '../../../components/Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';

// ----------------------------------------------------------------------
const addOrUpdatePageUrl: string = '/dashboard/AddOrEditPackage';
export default function MainUpdatePackagePage() {
  const fetchConfig: UrlConfig<any> = {
    url: API_URL.PACKAGES,
    params: undefined,
  };
  const { tableData, setTableData, handleUpdateItemClick, handleDeleteItemClick } =
    useTableHook<Package>(API_URL.PACKAGES, addOrUpdatePageUrl);
  const { data: fetchedData, loading, setLoading } = useFetch<Package[]>(fetchConfig);

  useEffect(() => {
    if (fetchedData) {
      setTableData(fetchedData);
    } else {
      setTableData([]);
      setTableData([]);
    }
  }, [fetchedData]);

  const TABLE_COLS_DATAGRID: GridColumns<Package> = [
    {
      field: 'name',
      headerName: 'Nazwa',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
    },

    {
      field: 'description',
      headerName: 'Opis',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      minWidth: 200,
    },

    /*  {
            field: 'configuration',
            headerName: 'Konfiguracja',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            minWidth: 200,
        }, */
    {
      field: 'url',
      headerName: 'Url',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
    },

    {
      field: ' ',
      headerName: '',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => {
        const rowIndex = tableData?.findIndex((row) => row.id === params.id);
        return (
          <MoreMenuReusable
            itemToEditOrDeleteTableIndex={rowIndex}
            editItem={handleUpdateItemClick}
            editTitle={'Szczegóły'}
          />
        );
      },
    },
  ];
  return (
    <RenderContentOrLoadingSpinner isLoading={loading}>
      <MainPageWithTable
        firstCellSortOrder={'asc'}
        pageHelmetTitle={'UpdatePackage'}
        pageTitle={'Kampanie'}
        buttonLabel={'Nowa'}
        formPageUrl={'/dashboard/AddOrEditPackage'}
        gridColumns={TABLE_COLS_DATAGRID}
        tableData={tableData}
      />
    </RenderContentOrLoadingSpinner>
  );
}
