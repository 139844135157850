import { Box, Stack, Typography } from '@mui/material';

interface LogSectionProps {
  logsData: string;
}

const checkIfStringStartsWithDash = (inputString: string | undefined): boolean => {
  if (inputString) {
    const inputStringFirstChatacter = inputString[0];
    if (inputStringFirstChatacter === '-') {
      return true;
    }
  }
  return false;
};
const returnStringWithoutInitialPunctation = (
  inputString: string | undefined
): string | undefined => {
  let result;
  if (checkIfStringStartsWithDash(inputString)) {
    result = inputString
      ?.split('-')
      .filter((x, index) => index !== 0 && x)
      .join('-');
  } else if (inputString && !checkIfStringStartsWithDash(inputString)) {
    result = inputString
      ?.split('.')
      .filter((x, index) => index !== 0 && x)
      .join('.');
  }
  return result;
};
const returnInitialPunctation = (inputString: string | undefined): string | undefined => {
  let result: string | undefined;
  if (inputString && checkIfStringStartsWithDash(inputString)) {
    result = inputString[0];
  } else if (inputString && !checkIfStringStartsWithDash(inputString)) {
    result = inputString ? `${inputString?.split('.')[0]}.` : undefined;
  }
  return result;
};
export const LogsDetailsSection = (props: LogSectionProps) => {
  const { logsData } = props;
  const errorDetailsWithNewLines = logsData?.split('\n').filter((str) => Boolean(str));
  // rest of your code

  return (
    <Box>
      {logsData &&
        errorDetailsWithNewLines?.map((e, index) => (
          <Stack direction={'row'} spacing={2} key={index + Math.random()}>
            <Typography variant={'subtitle1'} paddingLeft={checkIfStringStartsWithDash(e) ? 4 : 0}>
              <strong>{returnInitialPunctation(e)}</strong>
            </Typography>
            {e && <Typography>{returnStringWithoutInitialPunctation(e)}</Typography>}
          </Stack>
        ))}
      {!logsData && <Typography>Brak danych-Nie podjęto próby aktualizacji.</Typography>}
    </Box>
  );
};
