import { useEffect, useState } from 'react';
import { backupperAPI } from '../utils/axios';
import { UrlConfig } from './useFetchHooksi';

export interface DeleteProcessState {
  deletedId: string | null;
  deleting: boolean;
}

export function useDeleteHook<R>(urlConfig: UrlConfig<any>, loadingInitialParam?: boolean) {
  const loadingInitial =
    loadingInitialParam !== null && loadingInitialParam !== undefined ? loadingInitialParam : true;

  const [deleteProcessState, setDeleteProcessState] = useState<DeleteProcessState>({
    deletedId: null,
    deleting: false,
  });
  const [deleteSuccessfull, setDeleteSuccessfull] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);
  const fetchUrl =
    urlConfig.url && deleteProcessState.deletedId
      ? `${urlConfig.url}/${deleteProcessState.deletedId}`
      : undefined;
  useEffect(() => {
    if (deleteProcessState.deleting && fetchUrl && deleteProcessState.deletedId) {
      setDeleteSuccessfull(false);
      setError(null);
      backupperAPI
        .delete(fetchUrl, { params: urlConfig.params })
        .then((resp) => {
          const responseCode = resp.status === 204;
          setDeleteSuccessfull(responseCode);
          setError(null);
        })
        .catch((error) => {
          setError(error);
          setDeleteSuccessfull(false);
        })
        .finally(() => {
          const currentDeleteProcessState = { ...deleteProcessState };
          setDeleteProcessState({ ...currentDeleteProcessState, deleting: false });
        });
    }
  }, [urlConfig.url, urlConfig.params, deleteProcessState.deleting, deleteProcessState.deletedId]);

  return {
    deleteSuccessfull,
    error,
    deleteProcessState,
    setDeleteProcessState,
  };
}
