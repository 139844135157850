import { useCallback } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GridColumns, GridSortDirection } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ExportIcon from '@mui/icons-material/Download';
import { API_URL, UrlConfig, useFetch } from '../../../hooks/useFetchHooksi';
import axios from 'axios';
import { DataGridCustomOnSurFace } from '../DataGrid/DataGridOnSurface';
import { DetailPageSectionHeader } from '../DetailPageCommonComponents/DetailPageSectionHeader';

interface MainPageWithTableProps {
  formPageUrl?: string;
  tableTitle: string;
  buttonLabel?: string;
  gridColumns: GridColumns;
  fetchDataUrl?: API_URL;
  firstCellSortOrder?: GridSortDirection;
  formPageData?: any;
  setTableData?: (data: any[]) => void;
  tableData?: any[];
}

export const TableWithTitleAndAddButton = (props: MainPageWithTableProps) => {
  const navigate = useNavigate();
  const { gridColumns, formPageUrl, tableTitle, buttonLabel, fetchDataUrl, tableData } = props;
  const buttonLabelAdd = buttonLabel;
  const buttonLabelExport= buttonLabel ?? 'Eksportuj';
  const formPageData = props.formPageData ?? undefined;

  const downloadExcel=()=>{
    setLoading(true);
    const axiosConfig ={
      responseType: 'blob',
    }
    axios.get('https://giftsorderapi20230705185024.azurewebsites.net/gifts/exporttoexcel', {responseType: 'blob'}).then((response)=>{
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}_Gifts.xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch((error)=>{
      setLoading(false);
      console.log(error.message);
    })
  }

  const handleNavigateToAddForm = useCallback(() => {
    if (formPageUrl) {
      navigate(formPageUrl, { state: formPageData });
    }
  }, [navigate, formPageUrl]);

  const fetchConfig: UrlConfig<any> = {
    url: fetchDataUrl,
    params: undefined,
  };
  const { data: fetchedData, loading, setLoading } = useFetch<any>(fetchConfig);
  const tableDataToDisplay: any[] = tableData ?? fetchedData;

  return (
    <Box id={`tableSection${tableTitle}`} marginBottom={4}>
      {formPageUrl && (
        <Grid container width={'auto'} justifyContent={'end'}>
          
            <Button variant="contained" onClick={downloadExcel} startIcon={<ExportIcon />}>
              {buttonLabelExport}
            </Button>
            
            <Button variant="contained" onClick={handleNavigateToAddForm} startIcon={<AddIcon />}>
              {buttonLabelAdd}
            </Button>
            
        </Grid>
      )}

      <DetailPageSectionHeader title={tableTitle} />
      <DataGridCustomOnSurFace
        setTableData={props.setTableData}
        initialSortingOrder={props.firstCellSortOrder}
        rows={tableDataToDisplay || []}
        columns={gridColumns}
        rowCount={tableDataToDisplay ? tableDataToDisplay?.length : 0}
        getRowId={(row) => {
          const uniqueIdentyfier = row?.id;
          const result = uniqueIdentyfier ?? `Id${Math.random()}`;
          return result;
        }}
      />
    </Box>
  );
};
