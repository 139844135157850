import { Typography } from '@mui/material';

class DetailPageSectionHeaderProps {
  title: string = '';

  mt?: number | string;

  mb?: number | string;

  mx?: number | string;

  widthAutoWithTextAlignCenter?: boolean;
}

export const DetailPageSectionHeader = (props: DetailPageSectionHeaderProps) => {
  const { title, mb, mt, mx, widthAutoWithTextAlignCenter } = props;

  return (
    <Typography
      variant="h6"
      sx={{
        mt: props.mt ?? 0,
        mb: props.mb ?? 2,
        mx: props.mx ?? 0,
        width: widthAutoWithTextAlignCenter ? 'auto' : 'initial',
        textAlign: widthAutoWithTextAlignCenter ? 'center' : 'initial',
      }}
    >
      {title}
    </Typography>
  );
};
