import {
  DataGrid,
  DataGridProps,
  gridClasses,
  GridSortDirection,
  GridSortItem,
  plPL,
} from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';
import { getNextGridSortDirection } from '@mui/x-data-grid/hooks/features/sorting/gridSortingUtils';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  // header column styles
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: theme.palette.grey[300],
    /*  [theme.breakpoints.down('md')]: {
            minWidth: '200px !important',
        }, */
  },

  /* // cell no shrink
    '& .MuiDataGrid-cell': {
        [theme.breakpoints.down('md')]: {
            minWidth: '200px !important',
        },
    },
    '& .MuiDataGrid-colCell': {
            minWidth: '200px !important',
        // Add any additional styling for column cells
    }, */
  // disable cell border highlight on click
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
    border: 'none',
  }, // odd row styles
  [`& .${gridClasses.row}.even`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ), // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },

  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ), // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));
export interface ExtendedDataGridProps extends DataGridProps {
  initialSortingOrder?: GridSortDirection;
  setTableData?: (data: any) => void;
}

export const DataGridCustomOnSurFace = (props: ExtendedDataGridProps) => {
  const [pageSize, setPageSize] = useState(10);
  const { initialSortingOrder, setTableData, ...defaultDataGridProps } = props;
  // Ensure that columns have been provided
  if (!props.columns || props.columns.length === 0) {
    throw new Error('No columns provided to DataGridCustomOnSurFace');
  }

  // Ensure that columns have been provided
  if (!props.columns || props.columns.length === 0) {
    throw new Error('No columns provided to DataGridCustomOnSurFace');
  }

  // Get the field of the first column
  const firstColumnName = props.columns[0].field;

  // Set initial sorting model
  const [sortModel, setSortModel] = useState([
    {
      field: firstColumnName,
      sort: initialSortingOrder ?? 'desc',
    },
  ]);

  const handleSortModelChange = (model: any) => setSortModel(model);

  return (
    <Paper
      variant={'elevation'}
      elevation={4}
      component={Box}
      sx={{
        margin: 0.5,
        width: 'auto',
      }}
    >
      <StripedDataGrid
        {...defaultDataGridProps}
        autoHeight
        disableSelectionOnClick
        rowHeight={70}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        pageSize={pageSize}
        sortingOrder={[
          initialSortingOrder ?? 'desc',
          (initialSortingOrder && initialSortingOrder) === 'asc' ? 'desc' : 'asc',
          null,
        ]}
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sx={{
          width: '100%',
          overflow: {
            xs: 'scroll',
            md: 'auto',
          },
        }}
      />
    </Paper>
  );
};
