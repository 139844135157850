import { useLocation } from 'react-router-dom';
import { GridColumns } from '@mui/x-data-grid';
import { fromIsoStringToYearMonthDayHouerMinuteSecond } from '../../../utils/dateUtil';
import { TableProps } from '../../../pages/Companies/Types/Props';
import { MoreMenuReusable } from '../../Common/MoreMenuReusable';
import { TableWithTitleAndAddButton } from '../../Common/TableWithTitleAndAddButon/TableWithTitleAndAddButon';
import { useTableHook } from '../../../hooks/useTableHook';
import { API_URL } from '../../../hooks/useFetchHooksi';
import { Version } from '../../../Dtos/version';

export const VersionList = (props: TableProps) => {
  const { tableData } = props;
  const location = useLocation();
  const companyState = location.state;
  const {
    tableData: tableDataDisplay,
    setTableData,
    handleUpdateItemClick,
    handleDeleteItemClick,
    handleDetailsClick,
  } = useTableHook<Version>(API_URL.VERSIONS, undefined, undefined, tableData);

  const TABLE_COLS_DATAGRID: GridColumns = [
    /* {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    }, */
    {
      field: 'date',
      headerName: 'Data zdarzenia',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <> {fromIsoStringToYearMonthDayHouerMinuteSecond(params.value)}</>,
    },

    {
      field: 'message',
      headerName: 'Opis',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    // {
    //   field: 'code',
    //   headerName: 'kod',
    //   flex: 1,
    //   minWidth: 200,
    //   align: 'center',
    //   headerAlign: 'center',
    // },
    // {
    //   field: 'version1',
    //   headerName: 'Werrsja',
    //   flex: 1,
    //   minWidth: 200,
    //   align: 'center',
    //   headerAlign: 'center',
    // },

    /*    {
      field: ' ',
      headerName: '', 
      align: 'center',
      renderCell: (params) => {
        const rowIndex = params.api.getRowIndex(params.id);
        return <MoreMenuReusable 
            itemToEditOrDeleteTableIndex={rowIndex} 
            deleteItem={handleDeleteItem} 
        />;
      },
    } */
  ];
  /*
   * C:\trimar-AU\backups\TRIMAR-0001\express-file-upload-master.zip
   */
  return (
    <TableWithTitleAndAddButton
      tableTitle={'Historia zamówienia'}
      gridColumns={TABLE_COLS_DATAGRID}
      tableData={tableDataDisplay || []}
    />
  );
};
