import { Box } from '@mui/material';
import { CustomToast } from './customToast';
import { useAppSelector } from '../../hooks/reduxToolkitTypedHooks';

export const ErrorToastComponent = () => {
  const errorToastsFromStore = useAppSelector((state) => state.ui.errors);
  const visibleErrorToasts = errorToastsFromStore.filter((toast) => toast.visible);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        zIndex: 2000,
      }}
    >
      {visibleErrorToasts.map((toast, index) => (
        <CustomToast
          key={`errorToast${index}`}
          toastInfo={toast}
          alertProps={{ severity: 'error' }}
          type={'Error'}
          timeoutSeconds={5}
        />
      ))}
    </Box>
  );
};
