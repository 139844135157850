export interface GiftStatusState {
    description: string;
    color: string;
  }
  export const fromNumberStatusToDescriptionEndColor = (status: number): GiftStatusState => {
    /* enum Status { Planned:0, InProgress:1, Completed:2, Failed:3 } */
  
    switch (status) {
      case 0:
        return { description: 'Czeka na dane dostawy', color: 'info' };
      case 1:
        return { description: 'W trakcie pakowania', color: 'primary' };
      case 2:
        return { description: 'W transporcie', color: 'primary' };
      case 3:
        return { description: 'Dostarczona', color: 'success' };
      case 4:
        return { description: 'Zwrócona', color: 'secondary' };
      case 5:
        return { description: 'Anulowana', color: 'error' };
      default:
        return { description: 'Nieznany', color: 'secondary' };
    }
};  