// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/path';
import { CardGiftcard } from '@mui/icons-material';

// ----------------------------------------------------------------------

export const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{
      width: 1,
      height: 1,
    }}
  />
);

const ICONS = {
  user: getIcon('ic_user'),
  companies: getIcon('ic_company'),
  packages: getIcon('ic_update'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Zamówienia',
        path: PATH_DASHBOARD.general.companies,
        icon: <CardGiftcard/>,
      },
      // {
      //   title: 'Kampanie',
      //   path: PATH_DASHBOARD.general.packages,
      //   icon: ICONS.packages,
      // },
    ],
  },
];

export default navConfig;
