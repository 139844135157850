import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DetailPageSectionHeader } from './DetailPageCommonComponents/DetailPageSectionHeader';
import { displayValue } from '../../utils/RenderCellUtils';

export type RenderKeyValueProps = {
  name: string;
  value?: string | number | boolean | null;
  key?: string;
};

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  width: 600,
  fontSize: 16,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

const LabelCellStyled = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body1,
  width: 450,
  fontSize: 16,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

export function RenderKeyValue({ name, value }: RenderKeyValueProps) {
  return (
    <TableRow
      sx={{
        p: 0.5,
        '&:not(:last-child)': {
          borderBottom: '2px solid #e0e0e0',
        },
      }}
    >
      <LabelCellStyled
        sx={{
          p: 1,
        }}
      >
        {' '}
        <strong>{name}</strong>{' '}
      </LabelCellStyled>
      <TableCell
        sx={{
          p: 1,
        }}
      >
        {displayValue(value)}
      </TableCell>
    </TableRow>
  );
}

class KeyValueListProps {
  items: RenderKeyValueProps[] = [];

  sectionHeader?: string;
}

export function RenderKeyValueList(props: KeyValueListProps) {
  return (
    <>
      {props.sectionHeader && <DetailPageSectionHeader title={props.sectionHeader} />}
      <Table
        border={0}
        sx={{
          width: 'fit-content',
          height: 'auto',
        }}
      >
        <TableBody>
          {props.items.map((item, index) => (
            <RenderKeyValue name={item.name} value={item.value} key={`keyValue${item.name}`} />
          ))}
        </TableBody>
      </Table>
    </>
  );
}
