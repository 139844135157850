import { notNullOrUndefined } from './NullUndefinedHandlerUtils';

export const renderValueOrNullCellAsDash = (value: any) => {
  let result = '-';

  if (notNullOrUndefined(value)) {
    result = value;
  }

  return result;
};

export function mapInputRowsToDisplayedValueOrDashIfNull<T>(inputRows: Array<T>): Array<T> {
  const rowsTransformedForDisplay = inputRows?.map((paymentOrder: any): any => {
    const mappedPaymentOrder: Record<string, any> = {};
    Object.keys(paymentOrder).forEach((key) => {
      mappedPaymentOrder[key] = renderValueOrNullCellAsDash(paymentOrder[key]);
    });

    return mappedPaymentOrder;
  });
  return rowsTransformedForDisplay;
}

export const displayValue = (value: string | number | boolean | null | undefined) => {
  if (typeof value === 'boolean' || value === 'true' || value === 'false') {
    return value ? 'Tak' : 'Nie';
  }
  if (value !== null && value !== undefined) {
    return value;
  }
  return '-';
};
