import React, { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
// components
import Login from 'src/pages/auth/Login';
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import useAuth from 'src/hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import MainCompaniesPage from '../pages/Companies/MainCompaniesPage';
import AddOrEditCompanyPage from '../pages/Companies/AddOrEditCompanyPage';
import PlanActualizationFormPage from '../pages/Companies/PlannActualizationFormPage/planActualizationFormPage';
import { CompanyDetails } from '../pages/Companies/CompanyDetails/companyDetails';
import { ActualizationDetailsAndLogsPage } from '../pages/Companies/CompanyActualizationDetails/CompanyActualizationDetails';
import MainUpdatePackagePage from '../pages/UpdatesPackages/MainUpdatePackagePage/MainUpdatePackage';
import AddOrEditUpdatePackagePage from '../pages/UpdatesPackages/AddOrEditUpdatePackagePage/AddOrEditUpdatePackagePage';
import SetTrackingCodePage from '../pages/Companies/SetTrackingCodePage';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();
  console.log(isAuthenticated);

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        /*  {
                    path: 'register',
                    element: (
                        <GuestGuard>
                          <Register />
                        </GuestGuard>
                    ),
                  }*/
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        {
          path: 'new-password',
          element: <NewPassword />,
        },
        {
          path: 'verify',
          element: <VerifyCode />,
        },
        {
          path: 'login-unprotected',
          element: <Login />,
        },
        /*  { path: 'login-unprotected', element: <Login /> },
                  { path: 'register-unprotected', element: <Register /> },*/
      ],
    },
    {
      path: '/',
      element: <Navigate to="dashboard/gifts" /* replace */ />,
    },

    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'gifts',
          element: <MainCompaniesPage />,
        },
        {
          path: 'addGift',
          element: <AddOrEditCompanyPage />,
        },
        {
          path: 'setTrackGift',
          element: <SetTrackingCodePage />,
        },
        {
          path: 'PlanUpdate',
          element: <PlanActualizationFormPage />,
        },
        {
          path: 'giftDetails/:id',
          element: <CompanyDetails />,
        },
        {
          path: 'companyActualizationDetails/:id',
          element: <ActualizationDetailsAndLogsPage />,
        },
        {
          path: 'actualizationPackages',
          element: <MainUpdatePackagePage />,
        },
        {
          path: 'AddOrEditPackage',
          element: <AddOrEditUpdatePackagePage />,
        },
        {
          path: 'auth/login',
          element: <Login />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: '404',
          element: <NotFound />,
        },
        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
// AUTHENTICATION
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
/*const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
*/
// Dashboard

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
