import { Box } from '@mui/material';
import { uiActions } from '../../Store/ui-slice';
import { CustomToast } from './customToast';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxToolkitTypedHooks';

export const SuccessToastComponent = () => {
  const successToastFromStore = useAppSelector((state) => state.ui.successToast);
  const toastForForms = successToastFromStore.isFormAction;
  const dispatch = useAppDispatch();

  function handleClose() {
    dispatch(uiActions.closeSuccessToast());
  }

  return (
    <>
      {!toastForForms && (
        <Box
          sx={{
            width: '100%',
            position: 'fixed',
            zIndex: 2000,
          }}
        >
          <CustomToast
            toastInfo={successToastFromStore}
            alertProps={{ severity: 'success' }}
            type={'Success'}
            timeoutSeconds={2}
          />
        </Box>
      )}
    </>
  );
};
