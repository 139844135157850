import { useLocation } from 'react-router-dom';
import { GridColumns } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { fromIsoStringToYearMonthDayHouerMinuteSecond } from '../../../utils/dateUtil';
import { TableProps } from '../../../pages/Companies/Types/Props';
import { MoreMenuReusable } from '../../Common/MoreMenuReusable';
import { TableWithTitleAndAddButton } from '../../Common/TableWithTitleAndAddButon/TableWithTitleAndAddButon';
import { useTableHook } from '../../../hooks/useTableHook';
import { Update } from '../../../Dtos/update';
import { API_URL } from '../../../hooks/useFetchHooksi';
import { Backup } from '../../../Dtos/backup';

export const BackupList = (props: TableProps) => {
  const { tableData } = props;
  const location = useLocation();
  const companyState = location.state;
  const {
    tableData: tableDataDisplay,
    setTableData,
    handleUpdateItemClick,
    handleDeleteItemClick,
    handleDetailsClick,
  } = useTableHook<Backup>(API_URL.BACKUPS, undefined, undefined, tableData);

  const TABLE_COLS_DATAGRID: GridColumns = [
    {
      field: 'productName',
      headerName: 'Nazwa',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      
    },
    {
      field: 'productValue',
      headerName: 'Rozmiar',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },

    // {
    //   field: 'size',
    //   headerName: 'Rozmiar [MB]',
    //   flex: 0.5,
    //   minWidth: 200,
    //   align: 'center',
    //   headerAlign: 'center',
    // },
    // {
    //   field: 'path',
    //   headerName: 'Ścieżka',
    //   flex: 2,
    //   minWidth: 200,
    //   align: 'center',
    //   headerAlign: 'center',
    // },

    // {
    //   field: ' ',
    //   headerName: '' /* flex: 1, */,
    //   align: 'center',
    //   renderCell: (params) => {
    //     const rowIndex = tableData?.findIndex((row) => row.id === params.id);
    //     return (
    //       <MoreMenuReusable
    //         itemToEditOrDeleteTableIndex={rowIndex}
    //         deleteItem={handleDeleteItemClick}
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <TableWithTitleAndAddButton
      tableTitle={'Produkty'}
      gridColumns={TABLE_COLS_DATAGRID}
      tableData={tableDataDisplay || []}
    />
  );
};
