import { ReactNode } from 'react';
import { Box, Divider, Grid, Paper, Button } from '@mui/material';
import { DetailPageSectionHeader } from './DetailPageSectionHeader';

interface DetailPageSectionProps {
  title: string;
  children: ReactNode;
  useSurface?: boolean;
  width?: string;
}


export const DetailPageSection = (props: DetailPageSectionProps) => (
  <Grid
    item
    xs={12}
    sx={{
      overflow: 'auto',
    }}
  >
    {props.useSurface ? (
      <Paper elevation={10}>
        {props.title && <DetailPageSectionHeader title={props.title} />}
        {props.children}
      </Paper>
    ) : (
      <Box width={props.width ?? 'auto'}>
        {props.title && <DetailPageSectionHeader title={props.title} />}
        {props.children}
       
        <Divider sx={{ mb: 5 }} />
      </Box>
    )}
  </Grid>
);
