import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// components
import { useLocation } from 'react-router-dom';
import {
  AddOrEditCompanyForm,
  CompanyToEdit,
} from '../../components/Companies/AddEditCompanyForm/AddEditCompanyForm';

// ----------------------------------------------------------------------

export default function AddOrEditCompanyPage() {
  const location = useLocation();
  const { state } = location as any;
  const editedCompany: CompanyToEdit | undefined = state
    ? {
        id: state?.id,
        name: state?.name,
        /*  code: state?.code, */
        email: state?.email,
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title> Companies/AddOrEdit </title>
      </Helmet>
      <Container>
        <AddOrEditCompanyForm companyToEdit={editedCompany} />
      </Container>
    </>
  );
}
