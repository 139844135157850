import { ReactNode } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

interface PageWrapperProps {
  children: ReactNode;
  pageHelmetTitle: string;
}

export const PageWrapper = (props: PageWrapperProps) => (
  <Box
    /* paddingX={{xs:2, lg:4}} */
    /*  paddingY={1} */
    width={'auto'}
  >
    <Helmet>
      <title>{props.pageHelmetTitle}</title>
    </Helmet>
    {props.children}
  </Box>
);
