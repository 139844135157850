import { Autocomplete, Button, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import SelectField, { SelectOption } from 'src/components/Common/FormFields/select-field';
import { PostOrPutUrlConfig, usePostOrPutHook } from '../../../hooks/usePostHook';
import { API_URL } from '../../../hooks/useFetchHooksi';
import { Package } from '../../../Dtos/package';
import { UpdateDto } from './ActualizationDto';
import { Update } from '../../../Dtos/update';
import { fromDayjsObjectToIsoString } from '../../../utils/dateUtil';
import DateTimePicekrField from '../../Common/FormFields/dateTimePicekr-field';
import { CustomizedMuiLocalizationProvider } from '../../Common/Localization/muiLocalizationProvider';
import SelectFieldWithSearch from '../../Common/FormFields/SelectFieldWithSearch';

export interface UpdateFormState {
  id?: number;
  companyId: number;
  packageId: string;
  datePlanned: Dayjs | null;
}

export interface AddOrEditPackageFormProps {
  updateFormDto: UpdateDto;
  packagesToSelect: Package[];
}

export const PlanActualizationForm = (props: AddOrEditPackageFormProps) => {
  const { updateFormDto, packagesToSelect } = props;
  const createNewInitialValues: UpdateFormState = {
    ...updateFormDto,
    packageId: '',
    datePlanned: null,
  };

  const updateFormInitialValues: UpdateFormState = {
    ...updateFormDto,
    datePlanned: dayjs(updateFormDto?.datePlanned),
    packageId: String(updateFormDto?.packageId),
  };

  const packagesSelectOptions = packagesToSelect.map(
    (packageToSelect): SelectOption => ({
      value: String(packageToSelect.id),
      label: `${packageToSelect.name}-${packageToSelect.description}`,
    })
  );

  const initialState: UpdateFormState = updateFormDto.id
    ? updateFormInitialValues
    : createNewInitialValues;
  const urlConfig: PostOrPutUrlConfig<any> = {
    url: API_URL.UPDATES,
    method: updateFormDto.id ? 'put' : 'post',
    id: updateFormDto.id ? String(updateFormDto.id) : undefined,
  };
  const { loading, error, setLoading, setBody } = usePostOrPutHook<Update, UpdateDto>(urlConfig);

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ mb: 5 }}>
        {updateFormDto.id ? 'Edytuj zaplanowaną aktualizację' : 'Zaplanuj Nową aktualizację'}
      </Typography>
      <Formik
        initialValues={initialState}
        validationSchema={yup.object({
          packageId: yup.string().required('Pole wymagane'),
          datePlanned: yup
            .date()
            .test('required', function (value) {
              return dayjs(value).isValid();
            })
            .required('Pole wymagane'),
        })}
        validateOnMount
        onSubmit={(values) => {
          const id = updateFormDto.id ? Number(updateFormDto.id) : undefined;
          const datePlanned = fromDayjsObjectToIsoString(values.datePlanned) as string;
          const body: UpdateDto = {
            ...values,
            id,
            datePlanned,
            packageId: Number(values.packageId),
          };
          setBody(body);
          setLoading(true);
        }}
      >
        {(formikProps) => (
          <Form>
            <Grid container width={'auto'} justifyContent={'space-between'} rowGap={2}>
              <Grid item xs={12} sm={5}>
                <SelectFieldWithSearch
                  label={'Wybierz pakiet'}
                  name={'packageId'}
                  options={packagesSelectOptions}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <CustomizedMuiLocalizationProvider>
                  <DateTimePicekrField label={'Zaplanuj na'} name={'datePlanned'} fullWidth />
                </CustomizedMuiLocalizationProvider>
              </Grid>
            </Grid>
            <Grid container width={'auto'} justifyContent={'center'} mt={2}>
              <Grid item xs={12} sm={5}>
                <Button variant="contained" type="submit" fullWidth disabled={!formikProps.isValid}>
                  Zatwierdź
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
