import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ModalInfo {
  // actionName:string,
  modalName: ModalName;
  areaName: string;
  areaId?: string;
  capacity?: number;
  rotary?: number;
  season?: number;
}

export interface Toast {
  message?: string;
  status?: number;
  description?: string;
  url?: string;
  method?: string;
  headers?: {};
  params?: string;
  visible?: boolean;
  isFormAction?: boolean;
}

export interface SuccessToast extends Toast {}

export interface ErrorToast extends Toast {
  id?: number;
}

interface ErrorMessage {}

export type LanguageVersion = 'pl' | 'en' | 'de' | 'cs' | '';
type ModalName = 'modal-area-counters' | 'modal-area-capacity';

interface State {
  activeModal: ModalInfo | undefined;
  successToast: SuccessToast;
  errors: ErrorToast[];
  languageVersion: LanguageVersion;
  disableSubmitButton: boolean;
}

const initialState: State = {
  activeModal: undefined,
  successToast: {
    message: 'Operacja przebiegła pomyślnie',
    visible: false,
    isFormAction: false,
  },
  errors: [],
  languageVersion: 'pl',
  disableSubmitButton: false,
};

export const setIdToErrorBeforeErrorPushedToArray = (array: Array<any>): number => array.length; // it will equal index when error pushed

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    OpenModal(state, openModalEvent: PayloadAction<ModalInfo>) {
      const modalToOpen = openModalEvent.payload;
      state.activeModal = modalToOpen;
    },
    CloseModal(state) {
      state.activeModal = undefined;
    },
    openSuccessToast(state, newAction: PayloadAction<{ isFormAction: boolean }>) {
      const isFormSubmition = newAction.payload.isFormAction;
      state.successToast.visible = true;
      state.successToast.isFormAction = isFormSubmition;
    },
    closeSuccessToast(state) {
      state.successToast.visible = false;
      state.successToast.isFormAction = false;
    },
    openErrorToast(state, errorOccurredAction: PayloadAction<ErrorToast>) {
      const errorToast: ErrorToast = errorOccurredAction.payload;
      const errorId = setIdToErrorBeforeErrorPushedToArray(state.errors);
      errorToast.id = errorId;
      errorToast.visible = true;

      state.errors.push(errorToast);
    },
    closeErrorToast(state, closeToastAction: PayloadAction<ErrorToast>) {
      const errorToastToClose = { ...closeToastAction.payload };
      errorToastToClose.visible = false;
      const updatedErrors = [
        errorToastToClose,
        ...state.errors.filter((error) => error.id !== errorToastToClose.id),
      ];
      state.errors = updatedErrors;
    },
    setLanguageVersion(state, setLanguageAction: PayloadAction<LanguageVersion>) {
      state.languageVersion = setLanguageAction.payload;
    },
    setLanguageVersionFromLocaleStorage(state) {
      const lang = localStorage.getItem('lang');
      if (lang) {
        state.languageVersion = lang as LanguageVersion;
      }
    },

    disableSubmitButton(state) {
      state.disableSubmitButton = true;
    },
    enableSubmitButton(state) {
      state.disableSubmitButton = false;
    },
  },
});
export const uiActions = uiSlice.actions;
export { uiSlice };
