import { Button, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import InputField from '../../Common/FormFields/input-field';
import { PostOrPutUrlConfig, usePostOrPutHook } from '../../../hooks/usePostHook';
import { Company } from '../../../Dtos/company';
import { API_URL } from '../../../hooks/useFetchHooksi';
import { CompanyDto } from '../../../pages/Companies/Types/CreateOrUpdateCompanyDto';
import { RenderContentOrLoadingSpinner } from '../../Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';
import { UpdateTracikingCode } from 'src/pages/Companies/Types/UpdateOrderTrackingCodeDto copy';

export interface OrderToEdit {
  trackingCode?: string | undefined | null;
  orderId?: string | undefined | null;
}

interface SetTrackingCodeFormProps {
  orderToEdit?: OrderToEdit;
}

export const SetTrackingCodeForm = (props: SetTrackingCodeFormProps) => {
  const createNewInitialValues: OrderToEdit = {
    trackingCode: ''
  };

  const initialState: OrderToEdit = props?.orderToEdit
    ? props.orderToEdit
    : createNewInitialValues;
  const urlConfig: PostOrPutUrlConfig<any> = {
    url: API_URL.SET_TRACING,
    method: 'post',
    id: undefined,
  };
  const { loading, error, setLoading, setBody } = usePostOrPutHook<Company, UpdateTracikingCode>(urlConfig);

  return (
    <RenderContentOrLoadingSpinner isLoading={loading}>
      <>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            {'Zaktualizuj numer przesyłki'}
          </Typography>
          <Formik
            initialValues={initialState}
            validationSchema={yup.object({
              trackingCode: yup.string().required('Pole wymagane')
            })}
            validateOnMount
            onSubmit={(values) => {
              const orderId =
                props.orderToEdit && props.orderToEdit.orderId
                  ? props.orderToEdit.orderId
                  : undefined;
              const body: UpdateTracikingCode = {
                ...values,
                orderId,
              };
              setBody(body);
              setLoading(true);
            }}
          >
            {(formikProps) => (
              <Form>
                <Grid container width={'auto'} justifyContent={'space-between'} rowGap={2}>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      defaultProps={{
                        name: 'trackingCode',
                        label: 'Numer listu',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={5}>
                    <InputField
                      defaultProps={{
                        name: 'orderId',
                        label: 'kod',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid> */}
                </Grid>
                <Grid container width={'auto'} justifyContent={'center'} mt={2}>
                  <Grid item xs={12} sm={5}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={!formikProps.isValid}
                    >
                      Zapisz
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </>
    </RenderContentOrLoadingSpinner>
  );
};
