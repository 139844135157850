import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { GridColumns, GridSortDirection } from '@mui/x-data-grid';
import { PageWrapper } from './pageWrapper';
import { API_URL, UrlConfig, useFetch } from '../../../hooks/useFetchHooksi';
import { TableWithTitleAndAddButton } from '../TableWithTitleAndAddButon/TableWithTitleAndAddButon';

interface MainPageWithTableProps {
  pageHelmetTitle: string;
  formPageUrl?: string;
  pageTitle: string;
  buttonLabel?: string;
  gridColumns: GridColumns;
  fetchDataUrl?: API_URL;
  tableData?: any[] | null;
  firstCellSortOrder?: GridSortDirection;
  setTableData?: (data: any[]) => void;
}

export const MainPageWithTable = (props: MainPageWithTableProps) => {
  const navigate = useNavigate();
  const {
    gridColumns,
    pageHelmetTitle,
    formPageUrl,
    pageTitle,
    buttonLabel,
    fetchDataUrl,
    tableData,
  } = props;
  const buttonLabelAdd = buttonLabel ?? '';

  const handleNavigateToAddForm = useCallback(() => {
    if (formPageUrl) {
      navigate(formPageUrl);
    }
  }, [navigate, formPageUrl]);

  const fetchConfig: UrlConfig<any> = {
    url: fetchDataUrl,
    params: undefined,
  };
  const { data: fetchedData, loading, setLoading } = useFetch<any>(fetchConfig);
  const tableDataToDisplay: any[] = tableData ?? fetchedData;

  return (
    <PageWrapper pageHelmetTitle={pageHelmetTitle}>
      <TableWithTitleAndAddButton
        setTableData={props.setTableData}
        firstCellSortOrder={props.firstCellSortOrder}
        tableTitle={pageTitle}
        gridColumns={gridColumns}
        tableData={tableDataToDisplay || []}
        formPageUrl={formPageUrl}
      />
    </PageWrapper>
  );
};
