// PLUGINS IMPORTS //
import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { at } from 'lodash';

import NumberFormat from 'react-number-format';
import FormHelperText from '@mui/material/FormHelperText';
import { CustomTextField, muiHelperTextStyles } from './textFieldStyles';
import { MyNumberInput } from './MyNumberInput';

interface PropsType {
  errorText?: string;
  defaultProps: TextFieldProps;
  defaultValue?: any;
  type?: string;
  placeholder?: string;
  padding?: string | number;
  inputProps?: {};
  useNumberInput?: boolean;
  useMoneyFormat?: boolean;
  maxNumberValue?: number;
  isNumberAllowed?: (values: NumberFormat.NumberFormatValues) => boolean;
}

const InputField: FC<PropsType> = (props) => {
  const { errorText, ...restProps } = props;
  const [field, meta, helpers] = useField(props.defaultProps.name as string);

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (/* touched && */ error) {
      return error;
    }
    return ' ';
  };
  const useMoneyFormat: boolean =
    props?.useMoneyFormat || String(props?.defaultProps?.label)?.toUpperCase().includes('PLN');
  const usePositiveIntegerNumber: boolean =
    props?.useNumberInput || String(props?.defaultProps?.type) === 'number';
  const handleBlur = (event: any) => {
    // Call the default onBlur behavior
    field.onBlur(event);
    // Add your custom logic here
    if (useMoneyFormat || usePositiveIntegerNumber) {
      if (!event.target.value) {
        helpers.setValue('0');
      }
    }
  };
  return (
    <CustomTextField
      type={props.type}
      error={/* meta.touched && */ !!meta.error}
      defaultValue={props.defaultValue}
      InputLabelProps={{ shrink: true }}
      helperText={renderHelperText()}
      {...field}
      {...restProps.defaultProps}
      onBlur={handleBlur}
      InputProps={{
        ...props?.inputProps,
        inputComponent:
          usePositiveIntegerNumber || useMoneyFormat
            ? (MyNumberInput as any)
            : undefined /* nested input props is used to pass props to MyNumberInput, on default Mynumber input is set to use positive integer. If moneyForma is used it uses positive decimal with fixed decimal scale set to to */,
        inputProps: {
          moneyformat: useMoneyFormat,
          max: props?.maxNumberValue ? props.maxNumberValue : undefined,
          isallowed: props?.isNumberAllowed ? props.isNumberAllowed : undefined,
          type: 'text',
        },
      }}
      sx={{
        p: props?.padding ? props.padding : 'initial',
      }}
    />
  );
};
export default InputField;
