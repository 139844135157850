import { Button, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import InputField from '../../Common/FormFields/input-field';
import { PostOrPutUrlConfig, usePostOrPutHook } from '../../../hooks/usePostHook';
import { Company } from '../../../Dtos/company';
import { API_URL } from '../../../hooks/useFetchHooksi';
import { CompanyDto } from '../../../pages/Companies/Types/CreateOrUpdateCompanyDto';
import { RenderContentOrLoadingSpinner } from '../../Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';

export interface CompanyToEdit {
  name: string;
  email: string;
  /* code: string; */
  id?: string;
}

interface AddOrEditCompanyFormProps {
  companyToEdit?: CompanyToEdit;
}

export const AddOrEditCompanyForm = (props: AddOrEditCompanyFormProps) => {
  const createNewInitialValues: CompanyToEdit = {
    name: '',
    email: '',
    /* code: '', */
  };

  const initialState: CompanyToEdit = props?.companyToEdit
    ? props.companyToEdit
    : createNewInitialValues;
  const urlConfig: PostOrPutUrlConfig<any> = {
    url: API_URL.COMPANIES_ADD,
    method: props.companyToEdit ? 'put' : 'post',
    id: props.companyToEdit?.id ?? undefined,
  };
  const { loading, error, setLoading, setBody } = usePostOrPutHook<Company, CompanyDto>(urlConfig);

  return (
    <RenderContentOrLoadingSpinner isLoading={loading}>
      <>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            {props.companyToEdit ? 'Edytuj firmę' : 'Wyślij zaproszenie'}
          </Typography>
          <Formik
            initialValues={initialState}
            validationSchema={yup.object({
              name: yup.string().required('Pole wymagane'),
              email: yup.string().required('Pole wymagane'),
              /*  code: yup.string().required('Pole wymagane'), */
            })}
            validateOnMount
            onSubmit={(values) => {
              const id =
                props.companyToEdit && props.companyToEdit.id
                  ? Number(props.companyToEdit.id)
                  : undefined;
              const body: CompanyDto = {
                ...values,
                id,
              };
              setBody(body);
              setLoading(true);
            }}
          >
            {(formikProps) => (
              <Form>
                <Grid container width={'auto'} justifyContent={'space-between'} rowGap={2}>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      defaultProps={{
                        name: 'name',
                        label: 'Imię',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      defaultProps={{
                        name: 'email',
                        label: 'Adres e-mail',
                        type: 'email',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={5}>
                    <InputField
                      defaultProps={{
                        name: 'code',
                        label: 'kod',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid> */}
                </Grid>
                <Grid container width={'auto'} justifyContent={'center'} mt={2}>
                  <Grid item xs={12} sm={5}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={!formikProps.isValid}
                    >
                      Wyślij
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </>
    </RenderContentOrLoadingSpinner>
  );
};
