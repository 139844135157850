import { Alert, AlertProps, AlertTitle, Stack } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ErrorToast, SuccessToast, uiActions } from '../../Store/ui-slice';
import { useAppDispatch } from '../../hooks/reduxToolkitTypedHooks';

interface CustomToastProps {
  toastInfo: ErrorToast | SuccessToast;
  alertProps: AlertProps;
  timeoutSeconds?: number;
  type: 'Success' | 'Error';
}

export const CustomToast = (props: CustomToastProps) => {
  const toastInfo = props?.toastInfo;
  const type = props?.type;
  const url = toastInfo?.url;
  const message = toastInfo?.message;
  const description = toastInfo?.description;
  const severity = props?.alertProps?.severity;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutTimeout, setLogoutTimeout] = useState<number>(5);
  const [logoutTimeoutVisible, setLogoutTimeoutVisible] = useState<boolean>(false);
  const [showLogoutAndWaitButtons, setShowLogoutAndWaitButtons] = useState(false);

  const prepareUserFriendlyMessageForKnownErrors = (message: string): string | undefined => {
    let userFriendlyMessage;
    /*
        if (message) {
            if (message.includes("device with id")&&message.includes("not found")) {
                userFriendlyMessage = tr('Urządzenie nie istnieje:')+" "+message.split("id")[1]?.substring(0,3);
            } else if (message === "Loop A NOT active ( no car present! )") {
                userFriendlyMessage = tr('Pętla A nieaktywna.(Brak pojazdu!)');
            } else if (message.includes("Failed to send reset ticket counter to")) {
                userFriendlyMessage = tr('Nie udało się wysłać komendy resetu licznika biletów do wybranego urządzenia:')+" "+message.split("to")[2]?.substring(0,3);
            } else if (message.includes("Failed to send reset")) {
                userFriendlyMessage = tr('Nie udało się wysłać komendy reset do wybranego urządzenia:')+" "+message.split("to")[2]?.substring(0,3);
            } else if (message.includes("SP207 version of the parking system has this method not implemented yet")||message.includes("CommandNotImplemented")){
                userFriendlyMessage = tr('Wersja systemu parkingowego SP207 nie obsługuje tej funkcji.');
            } else if (message.includes("Failed to send print ticket to")) {
                userFriendlyMessage = tr('Nie udało się wysłać komendy drukowania biletu do wybranego urządzenia:')+" "+message.split("to")[2]?.substring(0,3);
            } else if (message.includes("area with id") && message.includes("not found") ) {
                userFriendlyMessage = tr('Obszar nie istnieje:')+" "+message.split("id")[1]?.substring(0,3);
            }
            /!* else if(message.includes("not found. This might happen a few seconds after a service restart")) {
                 userFriendlyMessage= tr('Nie znaleziono statusu obaszaru. Może to zdarzyć się kilka sekund po restarcie usługi.');
             }*!/

            else if (message.includes("Failed to run Closed Always on points:")) {

                userFriendlyMessage = tr('Nie udało się uruchomić komendy zawsze zamknięty dla punktów:') + message.split(
                    ":")[1];
            } else if (message.includes("Apos with id") && message.includes("not found") ) {
                userFriendlyMessage = tr('Kasa nie istnieje:')+" "+message.split("id")[1]?.substring(0,3);
            } else if (message.includes("status of apos with id")&& message.includes("not found")) {
                userFriendlyMessage = tr('Nie znaleziono statusu wybranej kasy:')+" "+message.split("id")[1]?.substring(0,3);
            } else if (message.includes("Failed to send reset to apos=")) {
                userFriendlyMessage = tr('Nie udało się wysłać komendy reset do kasy:') + " " + message.split("=")[1];
            } else if (message.includes("Failed sending Wol to apos id=")) {
                userFriendlyMessage = tr('Nie udało się wysłać komendy wyślij WoL do kasy:') + " " + message.split("=")[1];
            } else if (message.includes("Command not supported yet")) {
                userFriendlyMessage = tr('Wybrana komenda nie jest jeszcze obsługiwana.');
            } else if (message.includes("Failed to send 'disable' command to apos=")) {
                userFriendlyMessage = tr('Nie udało się wysłać komendy wyłącz do kasy:') + " " + message.split("=")[1];
            } else if (message.includes("Failed to send 'enable' command to apos=")) {
                userFriendlyMessage = tr('Nie udało się wysłać komendy włącz do kasy:') + " " + message.split("=")[1];
            }
            else if (message.includes("Failed to execute state=AlwaysClosed on point=")) {
                userFriendlyMessage= tr('Nie udało się uruchomić komendy zawsze zamknięty dla punktu:') + " " + message.split("=")[2]?.substring(0,2)
            } else if (message.includes("Failed to execute state=Normal on point=")) {
                userFriendlyMessage= tr('Nie udało się uruchomić komendy tryb pracy normalny dla punktu:') + " " + message.split("=")[2]?.substring(0,2)
            }
            else if (message.includes( "Failed to execute state=AlwaysOpen on point=")) {
                userFriendlyMessage= tr('Nie udało się uruchomić komendy zawsze otwarty dla punktu:') + " " + message.split("=")[2]?.substring(0,2)
            }
            else if (message.includes("Błąd ładownania definicji raportów z pliku")) {
                userFriendlyMessage = tr('Błąd ładownania definicji raportów z pliku');
            } else if (message.includes("Error occured during inserting new tariff")) {
                userFriendlyMessage = tr('Wystąpił błąd podczas dodawania/edycji taryfy- nie zapisano taryfy');
            } else if (message.includes("Error occured during updating tariff")) {
                userFriendlyMessage = tr('Wystąpił błąd podczas dodawania/edycji taryfy- nie zapisano taryfy');
            } else if (message.includes("Wystąpił błąd podczas obliczania taryfy")) {
                userFriendlyMessage = tr('Wystąpił błąd podczas obliczania taryfy');
            } else if (message.includes("Wystąpił błąd podczas pobierania danych taryfy")) {
                userFriendlyMessage = tr('Wystąpił błąd podczas pobierania danych wybranej taryfy');
            } else if (description?.includes("Hri, ClearNo or licence plate is in unrecognized format")) {
                userFriendlyMessage = tr(
                    "Podano paramer w niepoprawnym formacie. Podaj prawidłowe HRI (18 cyfr) lub krótki numer biletu (1-4 cyfr) lub numer tablicy rejestracyjnej (1-10 znaków zawierających litery i cyfry).");
            } else if (message.includes("Login is taken")) {
                userFriendlyMessage = tr("Login jest zajęty przez innego użytkownika, wybierz inny login.")
            } else if (url && (url.includes('/tariff/calculate'))) {
                userFriendlyMessage = message;
            }
            else if (message.includes("Network Error, Application is unable to connect to the server.")) {
                userFriendlyMessage = tr(
                    "Błąd połączenia z serwerem. Sprawdź połączenie z internetem. Jeśli problem będzie się powtarzał skontaktuj się z administratorem systemu.");
              if(location.pathname!=='/' && !showLogoutAndWaitButtons){
                 setShowLogoutAndWaitButtons(true);
              }
            }
            else if (message.includes("NotAuthorized")) {
                userFriendlyMessage = tr("Aplikacja przejściowo utraciła połączenie z serverem-Straciłeś autoryzację. Wymagane jest ponowne logowanie.Za chwilę nastąpi automatyczne przekierowanie na stronę logowania");
            }
            
            
        }
*/
    return userFriendlyMessage;
  };
  const checkIfErrorIsKnownErrorAndReturnUserFriendlyMessage = (): string | undefined => {
    let userFriendlyMessage = message;
    if (type === 'Error' && message) {
      userFriendlyMessage = prepareUserFriendlyMessageForKnownErrors(message);
    }
    return userFriendlyMessage;
  };
  const userFriendlyMessage = checkIfErrorIsKnownErrorAndReturnUserFriendlyMessage();

  useEffect(() => {
    if (props.timeoutSeconds && toastInfo?.visible) {
      setTimeout(() => {
        handleClose();
      }, props?.timeoutSeconds * 1000);
    }
  }, [props.timeoutSeconds, toastInfo?.visible]);

  const handleClose = () => {
    console.log('in on toast close');
    if (type === 'Error') {
      dispatch(uiActions.closeErrorToast(toastInfo));
    } else if (type === 'Success') {
      dispatch(uiActions.closeSuccessToast());
    }
  };

  return (
    <>
      {toastInfo?.visible && (
        <Alert {...props.alertProps} onClose={handleClose}>
          <AlertTitle>{type === 'Error' ? 'Błąd' : 'Sukces'}</AlertTitle>

          {userFriendlyMessage && <strong>{userFriendlyMessage}</strong>}
          {toastInfo?.url &&
            toastInfo?.message?.includes(
              'Network Error, Application is unable to connect to the server'
            ) && <strong>{`Url= ${toastInfo?.url}, `} </strong>}
          {logoutTimeout > 0 && logoutTimeoutVisible && (
            <strong>{` : ${logoutTimeout} ` + `sekund`}</strong>
          )}

          {!userFriendlyMessage && (
            <>
              {toastInfo?.status && <strong>{`Status= ${toastInfo?.status}, `}</strong>}
              {toastInfo?.url && <strong>{`Url= ${toastInfo?.url}, `} </strong>}

              {toastInfo?.params && <strong>{`UrlParams= ${toastInfo?.params}, `} </strong>}

              <strong>{toastInfo.message}</strong>
            </>
          )}
        </Alert>
      )}
    </>
  );
};
