import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// components
import { useLocation } from 'react-router-dom';
import { PlanActualizationForm } from '../../../components/Companies/Actualizations/PlanActualizationForm';
import { API_URL, UrlConfig, useFetch } from '../../../hooks/useFetchHooksi';
import { Package } from '../../../Dtos/package';
import { UpdateDto } from '../../../components/Companies/Actualizations/ActualizationDto';
import { RenderContentOrLoadingSpinner } from '../../../components/Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';

// ----------------------------------------------------------------------

export default function PlanActualizationFormPage() {
  const location = useLocation();
  const fetchConfig: UrlConfig<any> = {
    url: API_URL.PACKAGES,
    params: undefined,
  };
  const { data: fetchedData, loading, setLoading } = useFetch<Package[]>(fetchConfig);
  const { state } = location as any;

  const updateFormDto: UpdateDto = {
    id: state?.id,
    companyId: state?.companyId,
    packageId: state?.packageId,
    datePlanned: state?.datePlanned,
  };

  return (
    <>
      <Helmet>
        <title> Update/Schedule </title>
      </Helmet>
      <Container>
        <RenderContentOrLoadingSpinner isLoading={loading}>
          <PlanActualizationForm
            packagesToSelect={fetchedData ?? []}
            updateFormDto={updateFormDto}
          />
        </RenderContentOrLoadingSpinner>
      </Container>
    </>
  );
}
