import { FormHelperText, Grid, styled, TextField } from '@mui/material';

export const muiHelperTextStyles = {
  fontSize: '0.75rem', // Adjust font size
  lineHeight: '1', // Adjust line height
  marginTop: '1px', // Adjust margin
};

export const CustomFormHelperText = styled(FormHelperText)(({ theme }) => ({
  ...muiHelperTextStyles,
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormHelperText-root': {
    ...muiHelperTextStyles,
  },
}));
