import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { backupperAPI } from '../utils/axios';
import { UrlConfig } from './useFetchHooksi';

export interface PostOrPutUrlConfig<P> extends UrlConfig<P> {
  method: 'post' | 'put';
  id: string | undefined;
}

export function usePostOrPutHook<R, B>(urlConfig: PostOrPutUrlConfig<any>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [body, setBody] = useState<B | null>(null);
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const fetchUrl =
    urlConfig.url && urlConfig.id
      ? `${urlConfig.url}/${urlConfig.id}`
      : urlConfig.url
      ? urlConfig.url
      : undefined;
  const navigate = useNavigate();

  useEffect(() => {
    if (loading && fetchUrl && body) {
      setData(null);
      setError(null);
      setIsSuccess(false);
      backupperAPI({
        method: urlConfig.method,
        url: fetchUrl,
        data: body ? { ...body } : null,
        params: urlConfig.params,
      })
        .then((resp) => {
          setData(resp.data);
          setError(null);
          setIsSuccess(true);
        })
        .catch((error) => {
          setError(error);
          setData(null);
        })
        .finally(() => {
          setLoading(false);
          setBody(null);
        });
    }
  }, [urlConfig.url, urlConfig.params, loading, body]);

  useEffect(() => {
    const redirectToMainPageIfLoadingFinishedAndNoError = (): void => {
      if (!loading && isSuccess) {
        navigate(-1);
      }
    };
    setTimeout(() => {
      redirectToMainPageIfLoadingFinishedAndNoError();
    }, 2000);
  }, [loading, isSuccess]);

  return {
    data,
    error,
    loading,
    setLoading,
    setBody,
  };
}
