import { Button, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import InputField from '../../Common/FormFields/input-field';
import { PostOrPutUrlConfig, usePostOrPutHook } from '../../../hooks/usePostHook';
import { API_URL } from '../../../hooks/useFetchHooksi';
import { Package } from '../../../Dtos/package';
import { RenderContentOrLoadingSpinner } from '../../Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';

export interface PackageToEdit {
  name: string;
campaignId: string;
isActive:boolean;
description: string;
productName1: string;
productValue1: string;
productName2: string;
productValue2: string;
productName3: string;
productValue3: string;
productName4: string;
productValue4: string;
newOrderEmailAddress: string;
isInPostPackage:boolean;
isApaczkaSynch:boolean;
sizeX:number;
sizeY:number;
sizeZ:number;
weight:number;
logoUrl: string;
photoUrl: string;
colorCode: string;
url: string;
  id?: string;
}

interface AddOrEditPackageFormProps {
  packageToEdit?: PackageToEdit;
}

export const AddOrEditPackageForm = (props: AddOrEditPackageFormProps) => {
  const createNewInitialValues: PackageToEdit = {
    name: '',
campaignId: '',
isActive:true,
description: '',
productName1: '',
productValue1: '',
productName2: '',
productValue2: '',
productName3: '',
productValue3: '',
productName4: '',
productValue4: '',
newOrderEmailAddress: '',
isInPostPackage:false,
isApaczkaSynch:false,
sizeX:0,
sizeY:0,
sizeZ:0,
weight:0,
logoUrl: '',
photoUrl: '',
colorCode: '',
    url: '',
  };

  const initialState: PackageToEdit = props?.packageToEdit
    ? props.packageToEdit
    : createNewInitialValues;
  const urlConfig: PostOrPutUrlConfig<any> = {
    url: API_URL.PACKAGES,
    method: props.packageToEdit ? 'put' : 'post',
    id: props.packageToEdit?.id ?? undefined,
  };
  const { loading, error, setLoading, setBody } = usePostOrPutHook<any, Package>(urlConfig);

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <RenderContentOrLoadingSpinner isLoading={loading}>
        <>
          <Typography variant="h4" sx={{ mb: 5 }}>
            {props.packageToEdit ? 'Edytuj' : 'Dodaj'}
          </Typography>
          <Formik
            initialValues={initialState}
            validationSchema={yup.object({
              name: yup.string().required('Pole wymagane'),
              description: yup.string().required('Pole wymagane'),
            })}
            validateOnMount
            onSubmit={(values) => {
              const id =
                props.packageToEdit && props.packageToEdit.id
                  ? Number(props.packageToEdit.id)
                  : undefined;
              const body: Package = {
                ...values,
                id,
              };
              setBody(body);
              setLoading(true);
            }}
          >
            {(formikProps) => (
              <Form>
                <Grid container width={'auto'} justifyContent={'space-between'} rowGap={2}>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'name',
                        label: 'Nazwa',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'campaignId',
                        label: 'Identyfikator',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'isActive',
                        label: 'Aktywna',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'description',
                        label: 'Opis',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={12}><Typography variant="h6" sx={{ mb: 5 }}>Personalizacja produkótw</Typography>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productName1',
                        label: 'Nazwa',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productValue1',
                        label: 'Właściwości',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productName2',
                        label: 'Nazwa',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productValue2',
                        label: 'Właściwości',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productName3',
                        label: 'Nazwa',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productValue3',
                        label: 'Właściwości',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productName4',
                        label: 'Nazwa',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      defaultProps={{
                        name: 'productValue4',
                        label: 'Właściwości',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={12}><Typography variant="h6" sx={{ mb: 5 }}>Zamówienia</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'newOrderEmailAddress',
                        label: 'Adresy mailowe do wysyłki powiadowmień o nowym zamówieniu',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'isInPostPackage',
                        label: 'Możliwa dostawa do paczkomatu INPOST',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'isApaczkaSynch',
                        label: 'Sprawdzaj status zamówienia w serwisie Apaczka ',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={12}><Typography variant="h6" sx={{ mb: 5 }}>Wysyłka</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      defaultProps={{
                        name: 'sizeX',
                        label: 'Wysokość paczki (cm)',
                        type: 'number',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      defaultProps={{
                        name: 'sizeY',
                        label: 'Szerokość paczki (cm)',
                        type: 'number',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      defaultProps={{
                        name: 'sizeZ',
                        label: 'Głębokość paczki (cm)',
                        type: 'number',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      defaultProps={{
                        name: 'weight',
                        label: 'Waga paczki (kg)',
                        type: 'number',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={12}><Typography variant="h6" sx={{ mb: 5 }}>Personalizacja strony</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'logoUrl',
                        label: 'Logo',
                        type: 'url',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'photoUrl',
                        label: 'Zdjęcie w tle',
                        type: 'url',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      defaultProps={{
                        name: 'colorCode',
                        label: 'Kolor tła',
                        type: 'text',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container width={'auto'} justifyContent={'center'} mt={2}>
                  <Grid item xs={12} sm={5}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={!formikProps.isValid}
                    >
                      Zatwierdź
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      </RenderContentOrLoadingSpinner>
    </Paper>
  );
};
