import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Table } from '@mui/material';
import { PageWrapper } from '../../../components/Common/PageWraper/pageWrapper';
import { API_URL, UrlConfig, useFetch } from '../../../hooks/useFetchHooksi';
import { Update } from '../../../Dtos/update';
import {
  RenderKeyValue,
  RenderKeyValueList,
  RenderKeyValueProps,
} from '../../../components/Common/renderKeyValuePair';
import { DetailPageSection } from '../../../components/Common/DetailPageCommonComponents/DetailPageSection';
import { fromIsoStringToYearMonthDayHouerMinuteSecond } from '../../../utils/dateUtil';
import { RenderContentOrLoadingSpinner } from '../../../components/Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';
import { LogsDetailsSection } from '../../../components/Common/LogsDetailsSection/LogsDetailsFormatterComponent';

export const ActualizationDetailsAndLogsPage = () => {
  const location = useLocation();
  const actualizationState = location.state as Update;
  const { id } = useParams();
  const fetchConfig: UrlConfig<any> = {
    url: API_URL.UPDATES,
    params: undefined,
    id: String(id),
  };

  const { data: updateData, loading, setLoading } = useFetch<Update>(fetchConfig);
  const companyName = updateData?.company?.name;
  const datePlanned = fromIsoStringToYearMonthDayHouerMinuteSecond(updateData?.datePlanned);
  const dateCreation = fromIsoStringToYearMonthDayHouerMinuteSecond(updateData?.dateCreation);
  const dateUpdate = fromIsoStringToYearMonthDayHouerMinuteSecond(updateData?.dateUpdate);
  const companyDetailsTitle = `Szczegóły aktualizacji`;
  const logs = updateData?.logs ?? 'Brak logów';

  const detailsSectionData: RenderKeyValueProps[] = [
    {
      name: 'Nazwa firmy',
      value: companyName,
    },
    {
      name: 'Kod Firmy',
      value: updateData?.company?.code ? updateData?.company?.code : '-',
    },
    {
      name: 'UUID Firmy',
      value: updateData?.company?.uuid ? updateData?.company?.uuid : '-',
    },
    {
      name: 'Id aktualizacji',
      value: updateData?.id,
    },
    {
      name: 'Aktualizacja Zaplanowana na',
      value: datePlanned,
    },
    {
      name: 'Aktualizacja Utworzona',
      value: dateCreation,
    },
    {
      name: 'Aktualizacja Wykonana',
      value: dateUpdate,
    },

    {
      name: 'Status',
      value: updateData?.status,
    },
    {
      name: 'Nazwa pakietu aktualizacji',
      value: updateData?.package?.name ? updateData?.package?.name : '-',
    },
    {
      name: 'Opis pakietu aktualizacji',
      value: updateData?.package?.description ? updateData?.package?.description : '-',
    },
    {
      name: 'Id pakietu aktualizacji',
      value: updateData?.package?.id ? updateData?.package?.id : '-',
    },
    {
      name: 'Url pakietu aktualizacji',
      value: updateData?.package?.url ? updateData?.package?.url : '-',
    },
    /* {
      name: 'Konfiguracja pakietu aktualizacji',
      value: updateData?.package?.configuration ? updateData?.package?.configuration : '-',
    }, */
  ];
  return (
    <PageWrapper pageHelmetTitle={`ActualizationDetails/${id}`}>
      <RenderContentOrLoadingSpinner isLoading={loading}>
        <>
          <DetailPageSection title={companyDetailsTitle}>
            <RenderKeyValueList items={detailsSectionData} />
          </DetailPageSection>
          <DetailPageSection title={'Konfiguracja'}>
            <NavLink to={`/dashboard/AddOrEditPackage`} state={updateData?.package}>
              Szczegóły
            </NavLink>
          </DetailPageSection>
          <DetailPageSection title={'Logi aktualizacji'}>
            <LogsDetailsSection logsData={logs} />
          </DetailPageSection>
        </>
      </RenderContentOrLoadingSpinner>
    </PageWrapper>
  );
};
