import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormikProps } from 'formik';
import { isNotNullish } from '@reduxjs/toolkit/dist/query/utils/isNotNullish';
import Iconify from '../Iconify';
import { notNullOrUndefined } from '../../utils/NullUndefinedHandlerUtils';

export interface MoreMenuProps {
  deleteItem?: (tableIndex: number) => void;
  deleteTitle?: string;
  editItem?: (tableIndex: number) => void;
  editTitle?: string;
  backupListRedirect?: (tableIndex: number) => void;
  actualizationRedirect?: (tableIndex: number) => void;
  detailsRedirect?: (tableIndex: number) => void;
  detailsTitle?: string;
  itemToEditOrDeleteTableIndex?: number;
  formikBag?: FormikProps<any>;
  display?: string;
}

export type MuiColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | undefined;
export const MoreMenuReusable = (props: MoreMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<MuiColor>('primary');
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editItem = () => {
    if (
      props.editItem &&
      props.itemToEditOrDeleteTableIndex !== null &&
      props.itemToEditOrDeleteTableIndex !== undefined
    ) {
      props.editItem(props.itemToEditOrDeleteTableIndex);
    }
  };

  const handleDelete = () => {
    if (
      props.deleteItem &&
      props.itemToEditOrDeleteTableIndex !== null &&
      props.itemToEditOrDeleteTableIndex !== undefined
    ) {
      props.deleteItem(props.itemToEditOrDeleteTableIndex);
    }
  };

  /* transform below function to useCallback */

  const handleDetailsRedirect = () => {
    if (
      props.detailsRedirect &&
      props.itemToEditOrDeleteTableIndex !== null &&
      props.itemToEditOrDeleteTableIndex !== undefined
    ) {
      props.detailsRedirect(props.itemToEditOrDeleteTableIndex);
    }
  };

  return (
    <Box sx={{ display: props.display || 'block' }} title={'Więcej opcji'}>
      {/*  <MoreIcon onClick={handleOpenMenu} color={color}/> */}
      <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
        {/* @ts-ignore */}
        <Iconify icon={'eva:more-vertical-fill'} />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/*  {props.backupListRedirect && (
          <MenuItem onClick={handleBackupListRedirect}>
             @ts-ignore 
            <Iconify icon={'clarity:backup-line'} sx={{ mr: 2 }} />
            Kopie zapasowe
          </MenuItem>
        )} */}
        {/* {props.actualizationRedirect && (
          <MenuItem onClick={handleActualizationRedirect}>
             @ts-ignore 
            <Iconify icon={'mdi:eye-check'} sx={{ mr: 2 }} />
            Aktualizacje
          </MenuItem>
        )} */}
        {props.detailsRedirect && (
          <MenuItem onClick={handleDetailsRedirect}>
            {/* @ts-ignore */}
            <Iconify icon={'mdi:eye-check'} sx={{ mr: 2 }} />
            {props.detailsTitle ? props.detailsTitle : 'Szczegóły'}
          </MenuItem>
        )}
        {props.editItem && (
          <MenuItem onClick={editItem}>
            {/* @ts-ignore */}
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {props.editTitle ? props.editTitle : 'Edytuj'}
          </MenuItem>
        )}
        {props.deleteItem && (
          <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
            {/* @ts-ignore */}
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {props.deleteTitle ? props.deleteTitle : 'Usuń'}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
