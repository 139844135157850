import React from 'react';
import { useField } from 'formik';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

interface SelectOption {
  label: string;
  value: string | number;
}

interface PropsType {
  options: Array<SelectOption>;
  label: string;
  name: string;
  fullWidth?: boolean;
}

const SelectFieldWithSearch: React.FC<PropsType> = ({ label, options, ...props }) => {
  const [field, , helpers] = useField(props);

  // Make sure the value is a SelectOption
  const selectedValue = Array.isArray(field.value)
    ? null
    : options.find((option) => option.value === field.value);

  return (
    <Autocomplete
      {...props}
      {...field}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Wpisz nazwę aby wyszukać'} label={label} />
      )}
      value={selectedValue}
      onChange={(event, option) => {
        helpers.setValue(option && !Array.isArray(option) ? option.value : '');
      }}
    />
  );
};

export default SelectFieldWithSearch;
