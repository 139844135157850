import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// components
import { useLocation } from 'react-router-dom';
import {
  SetTrackingCodeForm,
  OrderToEdit,
} from '../../components/Companies/AddEditCompanyForm/SetTrackingCodeForm';

// ----------------------------------------------------------------------

export default function SetTrackingCodePage() {
  const location = useLocation();
  const { state } = location as any;
  const orderToEdit: OrderToEdit | undefined = state
    ? {
        orderId: state?.orderId,
        trackingCode: state?.trackingCode,
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title> Edit Tracking Code </title>
      </Helmet>
      <Container>
        <SetTrackingCodeForm orderToEdit={orderToEdit} />
      </Container>
    </>
  );
}
