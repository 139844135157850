import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { Dayjs } from 'dayjs';
import { TextFieldProps } from '@mui/material/TextField';
import { at } from 'lodash';
import { CustomTextField } from './textFieldStyles';

interface PropsType {
  label: string;
  name: string;
  setFieldValue?: any;
  onChange?: any;
  fullWidth?: boolean;
  helperText?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  noHelperTextContainer?: boolean;
}

const DateTimePickerField: FC<PropsType> = (props) => {
  const { label, name, onChange, ...rest } = props;
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (/* touched && */ error) {
      return error;
    }
    return ' ';
  };
  return (
    <DateTimePicker
      value={field.value}
      label={label}
      ampm={false}
      disabled={props.disabled}
      onChange={(value: Dayjs | null) => setFieldValue(field.name, value)}
      renderInput={(textFieldParams) => (
        <CustomTextField
          {...textFieldParams}
          helperText={props.noHelperTextContainer ? undefined : renderHelperText()}
          size={props?.size}
          error={!!meta.error}
          fullWidth={props?.fullWidth}
        />
      )}
    />
  );
};
export default DateTimePickerField;
