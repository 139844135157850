import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// components
import { useLocation } from 'react-router-dom';
import {
  AddOrEditPackageForm,
  PackageToEdit,
} from '../../../components/ActualizationPackages/AddEditPackageForm/AddEditPackageForm';
import { API_URL, UrlConfig, useFetch } from '../../../hooks/useFetchHooksi';
import { Package } from '../../../Dtos/package';

// ----------------------------------------------------------------------

export default function AddOrEditUpdatePackagePage() {
  const location = useLocation();
  const { state } = location as any;
  const editedPackage: PackageToEdit | undefined = state
    ? {
        id: state?.id,
        name: state?.name,
campaignId: state?.campaignId,
isActive: state?.isActive,
description: state?.description,
productName1: state?.productName1,
productValue1: state?.productValue1,
productName2: state?.productName2,
productValue2: state?.productValue2,
productName3: state?.productName3,
productValue3: state?.productValue3,
productName4: state?.productName4,
productValue4: state?.productValue4,
newOrderEmailAddress: state?.newOrderEmailAddress,
isInPostPackage:state?.isInPostPackage,
isApaczkaSynch:state?.isApaczkaSynch,
sizeX:state?.sizeX,
sizeY:state?.sizeY,
sizeZ:state?.sizeZ,
weight:state?.weight,
logoUrl: state?.logoUrl,
photoUrl: state?.photoUrl,
colorCode: state?.colorCode,
url: state?.url
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title> UpdatePackages/AddOrEdit </title>
      </Helmet>
      <Container>
        <AddOrEditPackageForm packageToEdit={editedPackage} />
      </Container>
    </>
  );
}
