import React from 'react';
import NumberFormat, { InputAttributes, NumericFormat } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  moneyformat?: boolean;
  max?: number;
  isallowed?: (values: NumberFormat.NumberFormatValues) => boolean;
}

export const MyNumberInput = React.forwardRef<
  // @ts-ignore
  NumberFormat<InputAttributes>,
  CustomProps
>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      max={props?.max ? props.max : undefined}
      /* isAllowed={(values) => {
               if(props.max &&values.floatValue && values.floatValue>props?.max){
                   return false;
               }
               else {
                   return true
               }
            }} */

      isAllowed={props?.isallowed ? props.isallowed : undefined}
      allowNegative={false}
      fixedDecimalScale={!!props?.moneyformat}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowedDecimalSeparators={['%']}
      decimalScale={props?.moneyformat ? 2 : 0}
    />
  );
});
